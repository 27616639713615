import React from "react";
import ReactDOM from "react-dom";
import { Spin } from "antd";
import * as StyledFunctions from "styled-components";

export type StyledDIV = StyledFunctions.StyledComponent<"div", any, {}, never>;
class SpinLoading {
  spinElementId = "spinElement";

  spinShow() {
    const el = document.getElementById(this.spinElementId);
    if (!el) {
      const div = document.createElement("div");
      div.style.width = "100%";
      div.style.height = "100%";
      div.style.top = "0px";
      div.style.left = "0px";
      div.style.position = "fixed";
      div.style.display = "block";
      div.style.zIndex = "10";
      div.id = this.spinElementId;
      let appendDiv = document.querySelectorAll("[data-widget='page-header']");
      if (
        appendDiv.length > 0 &&
        appendDiv[0].parentNode &&
        appendDiv[0].parentNode.children &&
        appendDiv[0].parentNode.children.length > 1
      ) {
        appendDiv[0].parentNode.children[1].append(div);
      } else {
        document.body.append(div);
      }
    } else {
      el.style.display = "block";
    }
    ReactDOM.render(
      <Spin
        size="large"
        style={{
          height: "calc(100%)",
          width: "calc(100%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10001,
          position: "relative",
          top: 0,
        }}
      />,
      document.getElementById(this.spinElementId)
    );
  }

  spinClose() {
    const el = document.getElementById(this.spinElementId);
    if (el) {
      setTimeout(() => {
        el.style.display = "none";
        ReactDOM.unmountComponentAtNode(el);
      }, 200);
    }
  }
}

export default new SpinLoading();
