import React from "react";
import { Icon, Modal } from "antd";
import * as StyledFunctions from "styled-components";
import Language from "@common/language";
import { PATH, Storage } from "@common";

const { success } = Modal;

export type StyledDIV = StyledFunctions.StyledComponent<"div", any, {}, never>;

class SessionLogin {
  lock: boolean = false;

  showConfirm() {
    if (this.lock) return;
    this.lock = true;
    const _this = this;
    success({
      title: Language.en("Session has expired").thai("เข้าสู่ระบบหมดอายุ").getMessage(),
      content: Language.en("We will take you to login page to sign in again.").thai("เราจะพาคุณไปที่หน้าเข้าสู่ระบบเพื่อลงชื่อเข้าใช้อีกครั้ง").getMessage(),
      icon: <Icon type="question-circle"/>,
      onOk() {
        _this.routerSign();
      },
    });
  };

  routerSign() {
    Storage.clear();
    // History.createBrowserHistory({
    //   basename: process.env.REACT_APP_AJAX_CLIENT_CONTEXT || "",
    // }).push(PATH.SIGN_IN);
    location.replace(`${process.env.REACT_APP_AJAX_CLIENT_CONTEXT}${PATH.SIGN_IN}`);
  }
}

export default new SessionLogin();
